import React from 'react';
import './HoverDetails.scss';

function HoverDetails(props) {
  const { value } = props;

  return (
    <figcaption className='table-item--gallery-thumbnail-details'>
      <span>{value}</span>
    </figcaption>
  );
}

export default React.memo(HoverDetails);
