import React from 'react';
import { Translate } from 'react-localize-redux';
import './TableItemDetails.scss';

function TableItemDetails(props) {
  const {
    article,
    diameter,
    height,
    amount,
    order
  } = props.data;

  return (
    <div className='table-item--details'>
      <h1 className='article'>
        # {article}
      </h1>
      <span className='diameter-label'>
        <Translate id='details.diameter' />
      </span>
      <span className='diameter-value'>
        {diameter}
      </span>
      <span className='height-label'>
        <Translate id='details.height'/>
      </span>
      <span className='height-value'>
        {height}
      </span>
      { amount ?
        <span className='amount-label'>
          <Translate id='details.amount'/>
        </span>
        : null
      }
      <span className='amount-value'>
        {amount}
      </span>
      { order ?
        <span className='order-label'>
          <Translate id='details.order'/>
        </span>
        : null
      }
      <span className='order-value'>
        {order}
      </span>
    </div>
  );
}

export default React.memo(TableItemDetails);
