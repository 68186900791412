import React from 'react';
import './ToTop.scss';
import arrow from '../images/white-arrow.png';

function ToTop(props) {
  const { width, height, tabIndex } = props;

  function scroll() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (
    <button
      className='totop-button'
      tabIndex={tabIndex}
      onClick={scroll}>
      <img
        src={arrow}
        width={`${width}px`}
        height={`${height}px`}
        alt='На верх сторінки'
      />
    </button>
  );
}

export default React.memo(ToTop);
