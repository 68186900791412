import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import './LanguageSelector.scss';
import { LANG_UKRAINIAN_CODE, LANG_RUSSIAN_CODE } from '../constants';

function LanguageSelector(props) {
  const { tabIndexUkrainian, tabIndexRussian, setActiveLanguage } = props;

  function setUkrainianAsActiveLanguage() {
    return setActiveLanguage(LANG_UKRAINIAN_CODE);
  }

  function setRussianAsActiveLanguage() {
    return setActiveLanguage(LANG_RUSSIAN_CODE);
  }

  return (
    <>
      <button
        className='language-selector'
        lang='uk'
        tabIndex={tabIndexUkrainian}
        onClick={setUkrainianAsActiveLanguage}
      >
        Українська
      </button>
      <button
        className='language-selector'
        lang='ru'
        tabIndex={tabIndexRussian}
        onClick={setRussianAsActiveLanguage}
      >
        Русский
      </button>
      <p className='copyright'>
        <Translate id='copyright' />
      </p>
    </>
  );
}

export default withLocalize(LanguageSelector);
