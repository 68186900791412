import React from 'react';
import { useInView } from 'react-intersection-observer'
import './Thumbnail.scss';
import HoverDetails from './HoverDetails';
import {
  INTERSECTION_OBSERVER_MARGIN,
  INTERSECTION_OBSERVER_THRESHOLD
} from '../constants';

function Thumbnail(props) {
  const { src, width, height, alt, hoverDetails } = props;
  const [ref, inView] = useInView({
    triggerOnce: true,
    // must be on one line - breaks otherwise
    rootMargin: `${INTERSECTION_OBSERVER_MARGIN}px`,
    threshold: INTERSECTION_OBSERVER_THRESHOLD,
  });

  return (
    <button
      ref={ref}
      className='table-item--gallery-thumbnail'
      width={width}
      height={height}
    >
      { inView ?
        (<figure>
          <img
            src={src}
            width={width}
            height={height}
            alt={alt}
          />
          <HoverDetails value={hoverDetails}/>
        </figure>) : null
      }
  </button>
  );
}

export default React.memo(Thumbnail);
