import React from 'react';
import './TableItem.scss';
import TableItemDetails from './TableItemDetails'
import TableItemGallery from './TableItemGallery'

function TableItem(props) {
  const { data, type, uid } = props;

  return (
    <div className='table-item'>
      <TableItemDetails data={data} type={type} />
      <TableItemGallery data={data} type={type} uid={uid} />
    </div>
  );
}

export default React.memo(TableItem);
