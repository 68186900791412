import React from 'react';
import './TableItemGallery.scss';
import '../styles/_photoswipe-gallery.scss';
import { PhotoSwipeGallery } from 'react-photoswipe';
import { withLocalize } from 'react-localize-redux';
import Thumbnail from './Thumbnail';
import { details, colorCodes } from '../locales.json';
import {
  IMG_FULL_WIDTH,
  IMG_FULL_HEIGHT,
  IMG_THUMBNAIL_WIDTH,
  IMG_THUMBNAIL_HEIGHT,
  LANG_UKRAINIAN_CODE,
} from '../constants';

function TableItemGallery(props) {
  const currentLang = props.activeLanguage;
  const { data, type } = props;
  const isRetail = type === 'retail';
  let lang;

  if (currentLang) {
    lang = currentLang.code === LANG_UKRAINIAN_CODE ? 0 : 1;
  }

  function getLocalizedHoverDetails(subarticle, withCaption = false) {
    const colors = colorCodes[subarticle];

    if (isRetail && currentLang) {
      return `${data.article}/${subarticle}`;
    } else if (!isRetail && currentLang) {
      const color = colors[lang];
      const caption = details.color[lang];

      return withCaption ? `${caption} - ${color}` : color;
    }

    return 'err';
  }

  function getLocalizedTitle(subarticle) {
    function getDetailsOf(key) {
      const value = data[key];
      if (value && currentLang) {
        return `${details[key][lang]} - ${value}`;
      } else return '';
    }

    const out = `
      ${getDetailsOf('article')}${type === 'retail' ? '/' + subarticle : ''} |
      ${!isRetail ? getLocalizedHoverDetails(subarticle, true) : ''} |
      ${getDetailsOf('diameter')} |
      ${getDetailsOf('height')} |
      ${getDetailsOf('amount')} |
      ${getDetailsOf('order')}
    `;

    return out
      .trim()
      .replace(/\s{2,}/g, '')
      .split('|')
      .filter(v => v)
      .join(' | ');
  }


  const galleryItems = data.subarticleList.map(subarticle => {
    const fileName = `${data.article}${subarticle}`
    const ext = 'jpg';
    const imgPath = `${process.env.PUBLIC_URL}/images/${type}`;

    const src = `${imgPath}/${fileName}.${ext}`
    const msrc = `${imgPath}/lazy/${fileName}lz.${ext}`
    const thumbnail = `${imgPath}/thumbnails/${fileName}th.${ext}`;

    return {
      src,
      msrc,
      thumbnail,
      w: IMG_FULL_WIDTH,
      h: IMG_FULL_HEIGHT,
      title: getLocalizedTitle(subarticle),
      hoverDetails: getLocalizedHoverDetails(subarticle)
    };
  });

  const options = {
    galleryUID: props.uid,
    closeOnScroll: false,
    preload: [0, 0],
    zoomEl: false,
    shareEl: false,
  };

  function getThumbnailContent(item) {
    const { thumbnail, title, hoverDetails } = item;

    return (
      <Thumbnail
        src={thumbnail}
        width={IMG_THUMBNAIL_WIDTH}
        height={IMG_THUMBNAIL_HEIGHT}
        alt={title}
        hoverDetails={hoverDetails}
      />
    );
  }

  return (
    <div className='table-item--gallery'>
      <PhotoSwipeGallery
        options={options}
        items={galleryItems}
        thumbnailContent={getThumbnailContent}
      />
    </div>
  );
}

export default withLocalize(React.memo(TableItemGallery));
