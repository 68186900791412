import React from 'react';
import './Table.scss';
import TableItem from './TableItem';
import data from '../data.json';

function Table(props) {
  const { type } = props;

  return (
    <main className='table'>
      <div className='visual-padding'></div>
        {data[type].map((item, i) =>
          <TableItem key={i} data={item} type={type} uid={i + 1} />)}
      <div className='visual-padding'></div>
    </main>
  );
}

export default React.memo(Table);
