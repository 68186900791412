import React from 'react';
import './Logo.scss';

function Logo(props) {
  const { text } = props;

  return (
    <div className='navbar--logo'>
      <span>{text}</span>
    </div>
  );
}

export default Logo;
