import React from 'react';
import './Hamburger.scss';

function Hamburger(props) {
  return (
    <button
      tabIndex='1'
      className={`
        navbar--hamburger
        hamburger--collapse
        ${props.isOpen ? 'is-open' : ''}
      `}
      onClick={props.onClick}
    >
      <span className='hamburger-box'>
        <span className='hamburger-inner'></span>
      </span>
    </button>
  );
}

export default React.memo(Hamburger);
