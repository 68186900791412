import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import './App.scss';
import Navbar from './Navbar';
import Table from './Table';
import Contact from './Contact';
import Footer from './Footer';
import ToTop from './ToTop';
import NoMatch from './NoMatch';
import locales from '../locales.json';
import {
  WINDOW_WIDTH_HANDHELD_THRESHOLD,
  LANG_UKRAINIAN_CODE,
  LANG_RUSSIAN_CODE
} from '../constants';

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isWidthHandheld: false,
      navigationOpen: false,
      hamburgerTabIndex: -1,
      retailTabIndex: 2,
      wholesaleTabIndex: 3,
      contactTabIndex: 4,
      navbarFooterLangUkrainianTabIndex: 5,
      navbarFooterLangRussianTabIndex: 6,
      mainFooterLangUkrainianTabIndex: -1,
      mainFooterLangRussianTabIndex: -1,
      toTopTabIndex: 7
    };

    this.initializeLanguageContext();

    this.updateTabIndexing = this.updateTabIndexing.bind(this);
    this.handleHamburgerClick = this.handleHamburgerClick.bind(this);
    this.contactTabIndex = this.contactView.bind(this);
    this.retailView = this.retailView.bind(this);
    this.wholesaleView = this.wholesaleView.bind(this);
  }


  initializeLanguageContext() {
    this.props.initialize({
      languages: [
        { name: 'Ukrainian', code: LANG_UKRAINIAN_CODE },
        { name: 'Russian', code: LANG_RUSSIAN_CODE }
      ],
      options: {
        defaultLanguage: LANG_UKRAINIAN_CODE,
        renderToStaticMarkup,
      }
    });

    this.props.addTranslation(locales);
  }

  // and attempt to load all images on the page before printing pdf is generated
  // will not work on slow internet speeds
  onBeforePrint() {
    window.addEventListener('beforeprint', () => {
      let pointer;
      let position = -1000;
      function lazyloadPrintFix() {
        if (position > 5000) {
          clearInterval(pointer);
        }
        console.log('tick')
        window.scrollTo(0, position += 1000);
      }

      pointer = setInterval(lazyloadPrintFix, 10);
    });
  }

  componentDidMount() {
    this.updateTabIndexing();
    window.addEventListener('resize', this.updateTabIndexing);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateTabIndexing);
  }

  updateTabIndexing() {
    this.setState({ isWidthHandheld: window.innerWidth <= WINDOW_WIDTH_HANDHELD_THRESHOLD }, () => {
      if ((this.state.navigationOpen && this.state.isWidthHandheld) || !this.state.isWidthHandheld) {
        this.setState({
          retailTabIndex: 2,
          wholesaleTabIndex: 3,
          contactTabIndex: 4,
        });
      } else if (!this.state.navigationOpen && this.state.isWidthHandheld){
         this.setState({
          retailTabIndex: -1,
          wholesaleTabIndex: -1,
          contactTabIndex: -1,
        });
      }

      if (this.state.isWidthHandheld) {
        this.setState({
          hamburgerTabIndex: 1,
          navbarFooterLangUkrainianTabIndex: -1,
          navbarFooterLangRussianTabIndex: -1,
          mainFooterLangUkrainianTabIndex: 5,
          mainFooterLangRussianTabIndex: 6,
        });
      } else {
        this.setState({
          hamburgerTabIndex: -1,
          navbarFooterLangUkrainianTabIndex: 5,
          navbarFooterLangRussianTabIndex: 6,
          mainFooterLangUkrainianTabIndex: -1,
          mainFooterLangRussianTabIndex: -1,
       });
      }
    });
   }

  handleHamburgerClick() {
    this.setState({ navigationOpen: !this.state.navigationOpen }, () => {
      this.updateTabIndexing();
    });
  }

  indexRedirect() {
    return <Redirect to='opt'/>
  }

  contactView() {
    return <Contact />;
  }

  retailView() {
    return <Table type='retail' />;
  }

  wholesaleView() {
    return <Table type='wholesale' />;
  }

  notFoundView() {
    return (
      <NoMatch data={{ url: window.location.href }}/>
    );
  }

  render() {
    return (
      <div>
        <Navbar
          isOpen={this.state.navigationOpen}
          hamburgerTabIndex={this.state.hamburgerTabIndex}
          retailTabIndex={this.state.retailTabIndex}
          wholesaleTabIndex={this.state.wholesaleTabIndex}
          contactTabIndex={this.state.contactTabIndex}
          tabIndexUkrainian={this.state.navbarFooterLangUkrainianTabIndex}
          tabIndexRussian={this.state.navbarFooterLangRussianTabIndex}
          handleClick={this.handleHamburgerClick}
        />
        <Switch>
          <Route exact path='/contact' render={this.contactView} />
          <Route path='/gloria-hairbands' render={this.retailView} />
          <Route path='/opt' render={this.wholesaleView} />
          <Route exact path='/' render={this.indexRedirect} />
          <Route render={this.notFoundView} />
        </Switch>
        <Footer
          tabIndexUkrainian={this.state.mainFooterLangUkrainianTabIndex}
          tabIndexRussian={this.state.mainFooterLangRussianTabIndex}
        />
        <ToTop
          tabIndex={this.state.toTopTabIndex}
          width={50}
          height={50}
        />
     </div>
    );
  }
}

export default withLocalize(App);

