import React from 'react';
import './Footer.scss';
import LanguageSelector from './LanguageSelector';

function Footer({ tabIndexUkrainian, tabIndexRussian}) {
  return (
    <footer className='main-footer'>
      <LanguageSelector
        tabIndexUkrainian={tabIndexUkrainian}
        tabIndexRussian={tabIndexRussian}
      />
    </footer>
  );
}

export default React.memo(Footer);
