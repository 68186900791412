export const WINDOW_WIDTH_HANDHELD_THRESHOLD = 1200;
export const IMG_FULL_WIDTH = 2500;
export const IMG_FULL_HEIGHT = 1660;
export const IMG_LAZY_WIDTH = 500;
export const IMG_LAZY_HEIGHT = 332;
export const IMG_THUMBNAIL_WIDTH = 180;
export const IMG_THUMBNAIL_HEIGHT = 120;
export const INTERSECTION_OBSERVER_MARGIN = 240;
export const INTERSECTION_OBSERVER_THRESHOLD = 0.2;
export const LANG_UKRAINIAN_CODE = 'ua';
export const LANG_RUSSIAN_CODE = 'ru';

