import React from 'react';
import { Translate } from 'react-localize-redux';
import './NoMatch.scss';

function NoMatch() {
  return (
    <main className='no-match-view'>
      <Translate
        id='noMatch'
      />
    </main>
  );
}

export default React.memo(NoMatch);
