import React from 'react';
import './Navbar.scss';
import Logo from './Logo';
import NavbarLink from './NavbarLink';
import Hamburger from './Hamburger';
import LanguageSelector from './LanguageSelector';

function Navbar(props) {
  const {
    isOpen,
    handleClick,
    hamburgerTabIndex,
    retailTabIndex,
    wholesaleTabIndex,
    contactTabIndex,
    tabIndexUkrainian,
    tabIndexRussian
  } = props;

  return (
    <div>
      <nav className={`navbar ${isOpen ? 'is-open' : ''}`}>
        <Logo text='GABCHAKTEX'/>
        <Hamburger
          tabIndex={hamburgerTabIndex}
          isOpen={isOpen}
          onClick={handleClick}
        />
        <NavbarLink
          tabIndex={retailTabIndex}
          to='/gloria-hairbands'
          id='navigation.retail'
        />
        <NavbarLink
          tabIndex={wholesaleTabIndex}
          to='/opt'
          id='navigation.wholesale'
        />
        <NavbarLink
          tabIndex={contactTabIndex}
          to='/contact'
          id='navigation.contact'
        />
        <div className='navbar--footer'>
          <LanguageSelector
            tabIndexUkrainian={tabIndexUkrainian}
            tabIndexRussian={tabIndexRussian}
          />
        </div>
      </nav>
      <br /> {/* is necessary for top margin on the table to work properly */}
    </div>
  );
}

export default React.memo(Navbar);
