import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import './NavbarLink.scss';

function NavbarLinks(props) {
  const LinkStyle = {
    textDecoration: 'none'
  };

  function handleClick() {
    window.scrollTo(0, 0);
  }

  const { to, id, tabIndex } = props;

  return (
    <Link
      className='navbar--link'
      to={to}
      tabIndex={tabIndex}
      style={LinkStyle}
      onClick={handleClick}
    >
      <Translate id={id}/>
    </Link>
  );
}

export default React.memo(NavbarLinks);
