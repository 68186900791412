import React from 'react';
import { Translate } from 'react-localize-redux';
import './Contact.scss';

function Contact() {
  return (
    <main className='contact-wrapper padding_top_ie'>
      <div className='contact'>
        <h1 className='contact--location-label'>
          <Translate id='contact.locationLabel' />
        </h1>
        <p className='contact--location-value'>
          <Translate id='contact.locationValue' />
        </p>
        <h1 className='contact--phone-label'>
          <Translate id='contact.phoneNumberLabel' />
        </h1>
        <p className='contact--phone-value'>
          +38050-587-99-21
        </p>
        <p className='contact--phone-value'>
          +38067-973-11-21
        </p>
        <h1 className='contact--email-label'>
          Email
        </h1>
        <p className='contact--email-value'>
          gabchaktex@gmail.com
        </p>
      </div>
    </main>
  );
}

export default React.memo(Contact);
